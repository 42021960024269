var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"section"},[_c('v-sheet',{staticClass:"overflow-y-auto",attrs:{"id":"scrolling-techniques-7","max-height":"90vh"}},[_c('v-container',{staticClass:"mt-10"},[_c('TransportFilters',{attrs:{"transports":_vm.transprtListTable},on:{"setTransportQuery":_vm.getTransportsList},model:{value:(_vm.isFiltersActive),callback:function ($$v) {_vm.isFiltersActive=$$v},expression:"isFiltersActive"}}),_c('div',{staticClass:"mt-8",attrs:{"id":"transport-list"}},[_c('div',{staticClass:"d-flex"},[_c('v-text-field',{staticClass:"user-search me-3 mb-4",attrs:{"expanded":"","placeholder":_vm.$t('Search'),"outlined":"","hide-details":"","dense":"","prepend-inner-icon":_vm.icons.mdiMagnify},on:{"change":function($event){return _vm.searchTransports()}},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-btn',{attrs:{"outlined":"","color":"grey-light"},on:{"click":function($event){$event.stopPropagation();_vm.isFiltersActive = !_vm.isFiltersActive}}},[_c('v-icon',{attrs:{"color":"grey"}},[_vm._v(" "+_vm._s(_vm.icons.mdiFilterVariant)+" ")])],1)],1),_c('v-data-table',{attrs:{"show-select":"","calculate-widths":"","items-per-page":5,"footer-props":{
                        'items-per-page-options': [5, 10, 15, 20],
                        'items-per-page-text': _vm.$t('RowsPerPage'),
                    },"header-props":{ 'sort-by-text': _vm.$t('SortBy') },"headers":_vm.transportTableColumns,"items":_vm.transprtListTable,"server-items-length":_vm.totalTransportListTable,"loading":_vm.isLoading,"options":_vm.options,"height":"500","fixed-header":""},on:{"update:options":function($event){_vm.options=$event},"update:page":_vm.paginationHandle},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
                    var item = ref.item;
return [_c('div',{staticClass:"extend-column d-flex flex-column ms-3"},[_c('router-link',{staticClass:"text--primary font-weight-semibold text-truncate cursor-pointer text-decoration-none",staticStyle:{"max-width":"12rem"},attrs:{"to":{ name: 'TransportProfile', params: { id: item.id } }}},[_vm._v(" "+_vm._s(item.name)+" ")])],1)]}},{key:"item.type",fn:function(ref){
                    var item = ref.item;
return [_c('span',{staticClass:"font-weight-medium text--primary text-center"},[_vm._v(" "+_vm._s(_vm.transportTypes[item.type])+" ")])]}},{key:"item.capacity",fn:function(ref){
                    var item = ref.item;
return [_c('span',{staticClass:"font-weight-medium text--primary text-center"},[_vm._v(" "+_vm._s(item.capacity)+" ")])]}},{key:"item.actions",fn:function(ref){
                    var item = ref.item;
return [_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.$router.push({ name: 'TransportProfile', params: { id: item.id } })}}},[_c('v-icon',{attrs:{"large":""}},[_vm._v(_vm._s(_vm.icons.mdiChevronRight))])],1)]}}],null,true),model:{value:(_vm.selectedTransports),callback:function ($$v) {_vm.selectedTransports=$$v},expression:"selectedTransports"}})],1)],1)],1),_c('v-app-bar',{attrs:{"height":"80","absolute":"","color":"white","elevate-on-scroll":"","scroll-target":"#scrolling-techniques-7"}},[_c('v-container',{staticClass:"d-flex"},[_c('v-toolbar-title',[_c('p',{staticClass:"d-none d-md-flex d-lg-flex d-xl-flex text-h5 font-weight-semibold black--text mt-1"},[_vm._v(" "+_vm._s(_vm.$t('TransportsList'))+" ")]),_c('p',{staticClass:"text-h6 font-weight-semibold black--text mt-1 d-flex d-xs-flex d-sm-flex d-md-none"},[_vm._v(" "+_vm._s(_vm.$t('TransportsList'))+" ")])]),_c('v-spacer'),_c('v-btn',{staticClass:"mx-3",attrs:{"color":"primary"},on:{"click":function($event){return _vm.$router.push({ name: 'TransportProfile', params: { id: 'add' } })}}},[_c('v-icon',{staticClass:"mr-2 d-none d-md-flex d-lg-flex d-xl-flex",attrs:{"color":"white"}},[_vm._v(" "+_vm._s(_vm.icons.mdiPlus)+" ")]),_c('v-icon',{staticClass:"d-flex d-xs-flex d-sm-flex d-md-none",attrs:{"color":"white"}},[_vm._v(" "+_vm._s(_vm.icons.mdiPlus)+" ")]),_c('span',{staticClass:"d-none d-md-flex d-lg-flex d-xl-flex"},[_vm._v(_vm._s(_vm.$t('CreateTransport')))])],1),(_vm.selectedTransports.length > 0)?_c('v-dialog',{attrs:{"transition":"dialog-top-transition","max-width":"600"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"outlined":"","color":"error"}},'v-btn',attrs,false),on),[_c('v-icon',{staticClass:"mr-2 d-none d-md-flex d-lg-flex d-xl-flex",attrs:{"color":"error"}},[_vm._v(" "+_vm._s(_vm.icons.mdiTrashCanOutline)+" ")]),_c('v-icon',{staticClass:"d-flex d-xs-flex d-sm-flex d-md-none",attrs:{"color":"error"}},[_vm._v(" "+_vm._s(_vm.icons.mdiTrashCanOutline)+" ")]),_c('span',{staticClass:"d-none d-md-flex d-lg-flex d-xl-flex"},[_vm._v(_vm._s(_vm.$t('Buttons.Delete')))])],1)]}},{key:"default",fn:function(dialog){return [_c('v-img',{staticClass:"mx-auto trashcan",attrs:{"width":"80","src":require("@/assets/icons/delete.png")}}),_c('v-card',[_c('v-card-text',{staticClass:"text-center"},[_c('p',{staticClass:"title font-weight-semibold black--text mt-12"},[_vm._v(_vm._s(_vm.$t('Alerts.Sure')))])]),_c('v-card-text',{staticClass:"text-center"},[(_vm.selectedTransports.length === 1)?_c('p',{staticClass:"body-2"},[_vm._v(" "+_vm._s(_vm.$t('Alerts.DeleteTransport'))+" "),_c('strong',{staticClass:"black--text"},[_vm._v(_vm._s(_vm.selectedTransports.map(function (entry) { return entry.name; }).join(', ')))]),_vm._v(" ?"),_c('br'),_vm._v(" "+_vm._s(_vm.$t('Alerts.Irreversible'))+" ")]):_c('p',{staticClass:"body-2"},[_vm._v(" "+_vm._s(_vm.$t('Alerts.SureDeleteTransports'))+" "),_c('strong',{staticClass:"black--text"},[_vm._v(_vm._s(_vm.selectedTransports.map(function (entry) { return entry.name; }).join(', ')))]),_vm._v(" ?"),_c('br'),_vm._v(" "+_vm._s(_vm.$t('Alerts.Irreversible'))+" ")])]),_c('v-card-actions',{staticClass:"justify-end mt-4"},[_c('v-btn',{staticClass:"px-5",attrs:{"color":"error"},on:{"click":_vm.deleteSelectedTransports}},[_vm._v(_vm._s(_vm.$t('Buttons.Delete')))]),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){dialog.value = false}}},[_vm._v(_vm._s(_vm.$t('Buttons.Cancel')))])],1)],1)]}}],null,false,4175368543)}):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }